<template>
    <div style="margin-bottom:2rem;">
        <div class="flex center">
            <div class="title2">案件查询</div>
        </div>
        <div class="flex wrap">
            <div class="col3 flex center" style="margin-top:1rem">
                <span style="width:100px;margin-right:10px">鉴定类别: </span>
                <el-select v-model="option" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                    </el-option>
                </el-select>
            </div>
            <div class="col3 flex center" style="margin-top:1rem">
                <span style="width:100px;margin-right:10px">鉴定案号: </span>
                <el-input style="width:217px" placeholder="如：闽[2016]物鉴字第一号" v-model="num"></el-input>
            </div>
            <div class="col3 flex center" style="margin-top:1rem">
                <span style="width:100px;margin-right:10px">委托人: </span>
                <el-input style="width:217px" placeholder="请输入委托人姓名" v-model="name"></el-input>
            </div>
        </div>
        <div style="font-size:0.9rem;margin-top:2rem">
            选定鉴定类别后，请输入完整的鉴定案号、委托人及相关字符，以便精准搜索！
        </div>
        <div style="margin-top:2rem;">
            <el-button style="width:100px" type="primary">查询</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            options:[
                {id:1,name:'法医临床鉴定'},
                {id:2,name:'法医毒物鉴定'},
                {id:3,name:'法医病理鉴定'},
                {id:4,name:'痕迹鉴定'},
                {id:5,name:'影像资料鉴定'},
            ],
            option:'法医临床鉴定',
            num:'',
            name:'',
        }
    }
}
</script>
<style scoped>
</style>